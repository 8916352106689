<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Id") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Address") }}</label>
            <b-form-input
                v-model="filter.like.address"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Description-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("House number") }}</label>
            <b-form-input
                v-model="filter.like.house"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Type-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Zip") }}</label>
            <b-form-input
                v-model="filter.like.zip"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("City") }}</label>
            <b-form-input
                v-model="filter.like.city"
                class="d-inline-block mr-1"
            />
          </b-col>




        </b-row>
      </b-card-body>
    </b-card>

    <!--Columns-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Columns") }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div class="text-center">
          <b-form-checkbox class="custom-control-inline" v-for="field in fields" v-model="field.active"
                           v-bind:key="field.key">{{ field.label }}
          </b-form-checkbox>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Real Estates') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onRealEstateSelect(0)">
                  <span class="text-nowrap">{{ msg('New real estate') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <b-table
            ref="deletedRealEstate-table"
            striped
            hover
            responsive
            v-bind:table-class="{'action-active':selectedRealEstates.length > 0}"
            v-bind:items="realEstates"
            v-bind:fields="selectedFields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <!--Actions-->
          <template #thead-top="data">
            <tr v-if="selectedRealEstates.length > 0">
              <th>
                <b-form-checkbox class="d-inline-block" v-bind:checked="isAllRealEstatesSelected"
                                 v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                                 @change="toggleAllRealEstates"
                />
              </th>
              <th :colspan="data.columns - 3">
                <div>
                  <b-button variant="success" size="sm" class="mr-1" @click="bulkEnableRealEsates">
                    <i class="fas fa-play mx-1"></i>{{ msg('Activate') }}
                  </b-button>
<!--                  <b-button variant="danger" size="sm" class="mr-1">
                    <i class="fas fa-stop mx-1"></i>{{ msg('Disable') }}
                  </b-button>-->
                  <b-button disabled variant="warning" size="sm" class="mr-1">
                    {{ msg('Advertise') }}
                  </b-button>
                  <b-badge variant="secondary" size="sm" class="mr-1">
                    <span>selected:</span> <span>{{ selectedRealEstates.length }}</span>
                  </b-badge>


                </div>
              </th>
              <th></th>
            </tr>
          </template>
          <!--Head selected-->
          <template v-if="canReadActiveRealEstates" #head(select)>
            <b-form-checkbox v-bind:checked="isAllRealEstatesSelected"
                             v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                             @change="toggleAllRealEstates"
            />
          </template>

          <template #cell(updatedDate)="data">
            {{ $moment(data.value,'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>
          <!--Cell selected-->
          <template v-if="canReadActiveRealEstates" #cell(select)="data">
            <b-form-checkbox
                v-model="selectedRealEstatesIds" :value="realEstates[data.index].id"
            />
          </template>
          <template #cell(attachments)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>

          <template #cell(realEstatePrices)="data">
            <div>
              {{ getPrices(data) }}
            </div>
          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge class="mb-1 mr-1" :variant="statusVariants(data.value)">Status: {{ data.value }}</b-badge>

            <b-badge class="mb-1 mr-1" :variant="data.item.nukiStatus!=null?'primary':'danger'">Nuki:
              {{ data.item.nukiStatus != null ? msg(data.item.nukiStatus) : "Kein Gerät" }}
            </b-badge>

            <b-badge :variant="data.item.advertise !=null && data.item.advertise===true ?'success':'danger'">
              Inserat: {{ getFeaturedStatus(data.item) }}
            </b-badge>
          </template>

          <template #cell(userIds)="data">
            <div v-for="userId in data.value" v-bind:key="userId">
              <b-badge class="mb-1" variant="info">{{ getUserFullName(userId) }}</b-badge>
            </div>
          </template>
          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">
                <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
              </div>
              <div style="margin-left: 5px">
                <strong>{{ msg('Zip') }}: </strong>{{ data.item.zip }}
              </div>
            </div>
          </template>
          <template #cell(id)="data">
            <b-button style="width: 95px" variant="outline-primary"
                      size="sm"
                      v-clipboard:copy="data.value"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="d-block">
              <i class="fas fa-copy"> </i> ID
            </b-button>

              <b-button
                  :disabled="isButtonDisabled(data.item)"
                  style=""
                  variant="outline-success"
                  size="sm"
                  @click="info(data.item, data.index, $event.target)"
                  class="btn d-block mt-1 mb-1"
                  v-b-tooltip
                  :title="tooltipText(data.item)"
              >
                {{ msg('Activate') }}
              </b-button>
          </template>
        </b-table>

        <b-modal :id="infoModal.id" title="Confirmation" @ok="activateRealEstate(infoModal.propertyId)"
                 ok-variant="success" @hide="resetInfoModal">
          <p class="my-4"> {{msg('activate?')}}</p>
          <p class="my-4"> {{'(' + addressName + ')' }}</p>
        </b-modal>


        <b-modal size="lg" :id="featureInfoModal.id"
                 :title="msg('Confirmation')"
                 :ok-title="featureInfoModal.type==='NONE'? msg('Deactivate'):
                 (featureInfoModal.type==='NORMAL'? msg('Activate for free') : msg('Chargeable activation'))"
                 @ok="featureRealEstateById(featureInfoModal.propertyId,featureInfoModal.type)"
                 ok-variant="primary" @hide="resetFeatureInfoModal">

          <div class="w-100" style="text-align: center">
            <p class="mb-3" style="color:white;font-size: x-large;font-weight: bold;">Inserat Optionen</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row class="features-group">
                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group" :aria-describedby="ariaDescribedby"
                                class="w-100"
                                value="NONE" v-model="featureInfoModal.type"
                                button-variant="outline-danger" button>
                    <div class="text-danger">
                      <p class="mb-0"><i class="far fa-stop-circle"></i></p>
                      <p>Nicht Inserieren</p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                value="NORMAL"
                                v-model="featureInfoModal.type"
                                button-variant="outline-primary" button>
                    <div>
                      <p class="mb-0"><i class="far fa-play-circle"></i></p>
                      <p>Normales Inserieren (Kostenlos)</p>
                    </div>
                  </b-form-radio>
                </b-col>


                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="PROPERTYPAGE"
                                button-variant="outline-success" button>
                    <div class="text-success">
                      <p class="mb-0"><i class="fas fa-tags"></i></p>
                      <p class="mb-0">Ausgewählte Immobilien- Immobilienseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€11/30 Tage</strong></p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="SEARCHPAGE" button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="fas fa-coins"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Suchseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€17/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <b-form-radio name="features-group" value="HOMEPAGE" :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="far fa-gem"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Startseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€60/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>
              </b-row>


            </b-form-group>


          </div>
        </b-modal>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.realEstate" size="xl" ref="realEstate-modal" id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <realEstate-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitRealEstate">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RealEstatePanel from './realEstatePanel'

export default {
  name: 'RealEstate',
  components: {
    RealEstatePanel
  },
  data() {
    return {
      loading: true,
      amount: 10,
      buid: null,
      selectedRealEstates: [],
      realEstates: [],
      fields: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        gte: {realEstatePrices:{}},
        lte: {realEstatePrices:{}},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
     page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {realEstate: false},
      addressName: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        type: null,
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
    , fields: {
      deep: true,
      handler() {
        localStorage["real-estates-fields"] = JSON.stringify(this.fields);
      }
    },
    selectedRealEstates: {
      handler() {
        this.$emit("selectedRealEstates", [...this.selectedRealEstates])
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('realEstate', ['allStatusNoActive','statusVariants','getPrices','getFullAddress','getFeaturedStatus','canReadActiveRealEstates']),
    ...mapGetters('data', ['getAllBranches']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
    selectedFields() {
      let fields = this.fields.filter(f => f.active);
      return (fields.length > 0) ? fields : this.fields;
    },
    selectedRealEstatesIds: {
      get() {
        return this.selectedRealEstates.map(p => p.id);
      },
      set(selectedRealEstatesIds) {
        let realEstates = this.realEstates.toObject("id");
        this.selectedRealEstates = selectedRealEstatesIds.map(pid => realEstates[pid]);
      }
    },
    isAllRealEstatesSelected() {
      let ids = this.selectedRealEstates.map(p => p.id);
      return this.realEstates.filter(p => !ids.includes(p.id)).length === 0;
    },
    allRealEstateStatus() {
      return this.allStatusNoActive.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    allAgents() {

      return this.getAllUsers
          .filter(us => us.roleId === 18)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allRealEstates() {
      return this.realEstates
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('realEstate', ['getAllDisabledRealEstates', 'activateRealEstate',
      'featureRealEstate', 'deFeatureRealEstate', 'copySuccess','bulkEnable','justimmoUpdate']),

    isButtonDisabled(property) {
      return property.attachments == null || property.attachments.length < 1 || property.realEstatePrices==null||
          property.realEstatePrices.basePricePerNight==null||property.realEstatePrices.basePricePerNight<0.01;
    },
    tooltipText(property) {
      return this.isButtonDisabled(property) ? this.msg('To activate and export please enter basis daily price and upload at least 1 image first'):'';
    },
    updateFromjustimmo() {
      let $this=this;
      if (this.buid == null) return
      this.justimmoUpdate({buid: this.buid})
          .then(() => {
            $this.refresh()
          })
    },
    bulkEnableRealEsates(){
      let $this = this;
      this.bulkEnable(this.selectedRealEstates)
          .then(() => {
            $this.refresh()
          })

    },
    toggleAllRealEstates(estate) {
      if (estate)
        this.selectedRealEstates = [...this.realEstates];
      else
        this.selectedRealEstates = [];
    },
    activateRealEstateById(realId) {
      let $this = this;
      this.activateRealEstate(realId)
          .then(() => {
            $this.refresh()
            window.open(`/real_estate`);
          })
    },
    deFeatureRealEstateById(realId) {
      let $this = this;
      this.deFeatureRealEstate(realId)
          .then(() => {
            $this.refresh()
          })
    },
    featureRealEstateById(realId) {
      let $this = this;
      this.featureRealEstate(realId)
          .then(() => {
            $this.refresh()
          })
    },
    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },
    setFields() {
      let $this = this
      let fields = [

        {
          key: 'updatedDate',
          label: $this.msg('Updated at'),
          sortable: true,
          active: true,
        },
        {
          key: 'id',
          label: $this.msg('Actions'),
          sortable: true,
          active: true

        },
        {
          key: 'attachments',
          label: 'Main image',
          sortable: true,
          active: true
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true,
          active: true
        },


        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true,
          active: true
        },

        {
          key: 'realEstatePrices',
          label: $this.msg('Base price per night'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.livingSpace',
          label: $this.msg('Living space'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.msg('Total space'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.rooms',
          label: $this.msg('Rooms'),
          sortable: true,
          active: true
        },

        /*  {
            key: 'actions',
            label: $this.msg('Actions'),
            sortable: true
          },*/
      ]
      this.fields = fields;

      try {
        let cachedFields = localStorage["real-estates-fields"];
        if (cachedFields != null) {
          let cachedFieldArr = JSON.parse(cachedFields);
          let cachedFieldsMap = cachedFieldArr.toObject("key");
          fields.forEach(field => field.active = cachedFieldsMap[field.key].active != null ? cachedFieldsMap[field.key].active : true);
        }
      } catch (e) {
        localStorage.setItem("real-estates-fields", null);
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address +' '+ item.house+'/'+item.apt : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllDisabledRealEstates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: $this.sort.direction,
      })
          .then(this.onRealEstatesRetrieve, (error) => $this.error = error)
    },
    onRealEstatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },
    onRealEstateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.realEstate = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(realEstate) {
      this.$router.push(`/real_estate/edit/${realEstate.id}`);
    },
    submitRealEstate() {
      this.$root.$emit('crm::submitRealEstate')
    },
    onSubmitted(server) {
      console.log("RealEstate -> onSubmitted()", server)
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.realEstate = false;
    }
  }
}
</script>

<style>

</style>
